<template>
    <div class="">
        
      <section 
        :class="(background) ? 'banner' : 'webkit-animation webkit-100per-800'" 
        :style="(background) ? 'background-image:url('+background+') !important' : ''" >

          <div class="container">
              <div class="row" style="background-color: rgba(0, 0, 0, 0.50);" >

                <div  class="col-sm-8 text-left" :class="(pgLoading) ? 'mtop25' : ''">
                  <h1 v-if="pgLoading" class="uppercase webkit-animation webkit-50per-100 mb-5"></h1>
                  <p v-if="pgLoading" class="heading_space webkit-animation webkit-50per-50 bottom15"></p>

                  <h1 v-if="!pgLoading" 
                      style="margin-top: 100px" 
                      :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                      v-html="($i18n.locale == 'ar') ? page.body_ar : page.body_en">
                  </h1>
                </div>
                      
                <div class="col-sm-4" :class="(pgLoading) ? 'mtop25' : ''">
                  <div v-if="pgLoading" class="webkit-animation webkit-100per-255"></div>
                  <div v-if="!pgLoading" class="row">

                    <form @submit.prevent="search" class="callus clearfix border_radius">
                      <div class="single-query form-group">
                        <model-select 
                            :options="options"
                            v-model="location"
                            class="form-control"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            placeholder="Location">
                        </model-select>
                      </div>

                      <div class="single-query form-group top10">
                        <model-select 
                            :options="items"
                            v-model="type"
                            class="form-control"
                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                            placeholder="Type">
                        </model-select>
                      </div>
                            
                      <!-- <div class="single-query form-group top10">
                        <input type="text" 
                              class="form-control" 
                              :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                              v-model="keyword" 
                              placeholder="Keyword (e.g. 'office')">
                      </div> -->

                      <button type="submit" 
                              class="btn-blue border_radius top15"
                              :class="(this.$i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                          {{ $t('app.search') }}
                      </button>
                    </form>

                  </div>
                </div>
            
            </div>
          </div>

      </section>

    </div>
</template>


<script>
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelSelect } from 'vue-search-select'

export default {
    name: 'Banner',
    components: {
        ModelSelect
    },
    data(){
        return {
            pgLoading: false,
            background: '',
            location: '',
            type: '',
            keyword: '',
            page: '',


            options: [],
            items: [],

        }
    },
    metaInfo() {
        return {
            title: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_description : this.page.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_keywords : this.page.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {

        this.fetchData();
        this.fetchLocations();
        this.fetchType();
    },
    methods: {
        //
        search () {
            this.$router.push({name: 'search', query:{location: this.location, type: this.type }})
        },

        fetchData() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/page/home',
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.page = res.data.row;
                this.background = (this.page.image) ? this.page.image.url : '';
            })
            .catch(() => {})
            .finally(() => {});
        },

        
        fetchLocations() {
            //this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/app/locations',
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                //this.pgLoading = false;
                this.options = res.data.options;
                // console.log('fffff', this.options);
            })
            .catch(() => {})
            .finally(() => {});
          },
        
        fetchType() {
            //this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const items = {
                url: window.baseURL+'/types',
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(items)
            .then(res => {
                //this.pgLoading = false;
                this.items = res.data.items;
                // console.log('fffff', this.items);
            })
            .catch(() => {})
            .finally(() => {});
          },


    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
